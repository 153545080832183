import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CV = () => (
  <Layout>
    <SEO title="Curriculum vitae" />
    <div className="container cv">

      <div className="row">
        <div className="col-lg-8">

          <div class="alert alert-dark text-center mb-5" role="alert">
            This CV is not finished yet, for now please visit my <a href="https://www.linkedin.com/in/samuelcotterall/" rel="me">LinkedIn</a>.
          </div>

          <h1 className="h2 mb-3">Samuel Cotterall<br /><span className="small text-muted">Curriculum vitae</span></h1>

          <p>I am a senior UI engineer with twelve years industry experience. </p>

          <p>I am currently interested in static site generation, PaaS, smart home and fintech.</p>

        </div>
      </div>

      <div className="row">
        <div className="col-lg-9">
          <hr />

          <section className="row">
            <div className="col-12 col-md-6">
              <h2 className="h3">Skills</h2>
              <ul>
                <li>HTML, CSS and Sass</li>
                <li>JavaScript, React and Vue</li>
                <li>Static site generators (Gatsby, Hugo)</li>
                <li>GraphQL and REST</li>
                <li>Figma and Sketch</li>
                <li>Accessible design</li>
              </ul>
            </div>
            <div className="col-12 col-md-6">
              <h2 className="h3">Working Knowledge</h2>
              <ul>
                <li>Serverless</li>
                <li>Amazon Web Services</li>
                <li>Infrastructure as Code (Terraform, Ansible)</li>
                <li>Virtualisation and Containerisation (Docker, Vagrant)</li>
              </ul>
            </div>
          </section>

          <hr />

          <section className="experience">
            <h2 className="mb-4">Experience</h2>

            <section className="mb-5">
              <header className="mb-2">
                <h3>Balance &amp; Symmetry</h3>
                <time>May 2015—Present</time>
              </header>

              <p>After leaving magneticNorth in 2015 I started Balance &amp; Symmetry.</p>
              <p>At the start of 2017 I was joined by my wife, Caroline, who has helped to expand our project management offering.</p>

            </section>


            <section className="mb-5">

              <header className="mb-2">
                <h3>Infinity Works (contract)</h3>
                <time>October 2018—Present</time>
              </header>

              <p>During my time at MAG-O I worked closely with the team at Infinity Works and, after leaving MAG-O, I was invited to help their then-new Manchester office with some further projects.</p>

              <h4 className="mt-4">LANCE Platform</h4>
              <p>With my knowledge of the VueJS framework I was brought in to provide UX/UI assistance to the <a href="https://lanceplatform.com/">tax reporting and compliance service</a>. </p>

              <p><span className="badge badge-secondary">Golang</span> <span className="badge badge-secondary">AWS</span> <span className="badge badge-secondary">VueJS</span> <span className="badge badge-secondary">Fintech</span></p>

              <h4 className="mt-4">National Careers Service — Understanding Myself Beta</h4>

              <p>At the start of 2019 I was asked to help deliver a GDS Beta for the <a href="https://nationalcareers.service.gov.uk/">National Careers Service</a>, working with IWC’s engineers and the design and research team at <a href="https://hippodigital.co.uk/">Hippo Digital</a>. </p>

              <p><span className="badge badge-secondary">Azure</span> <span className="badge badge-secondary">GDS</span></p>

              <h4 className="mt-4">FibreNation</h4>

              <p>For the last six months I have working with IWC’s engineers to build a number of proof-of-concepts for the <a href="https://fibrenation.co.uk">FTTP provider</a>.</p>

              <p><span className="badge badge-secondary">AWS</span> <span className="badge badge-secondary">React</span> <span className="badge badge-secondary">WordPress</span> <span className="badge badge-secondary">Telecoms</span></p>

            </section>

            <section className="mb-5">
              <header className="mb-2">
                <h3>Manchester Airport Group (contract)</h3>
                <time>August 2017—October 2018</time>
              </header>

              <h4 className="mt-4">UI Engineer for Retail</h4>

              <p>I was brought in to MAG-O (Manchester Airports Group’s in-house agency) to offer some much-needed front-end support on their existing retail experience; working with their UX team to test and improve the customer journey within a somewhat limited off-the-shelf platform (Umbraco, UCommerce).</p>

              <p>At the beginning of 2018, as the team moved into their new premises and rapidly grew in size, the project underwent a major re-platforming: transitioning to a more decoupled architecture (.NET Core 2, AWS, Serverless) and making use of tools such as WebPack, ES6 and Vue.</p>

              <p>During this time I continued to work closely with UX designers/researchers: observing user testing sessions, prototyping interfaces and developing A/B tests in Optimizely.</p>

              <p>During my time at MAG-O I was able to gain experience of working on a large codebase that was shared across multiple teams, using concepts such as mainline development and feature flagging to achieve continuous delivery.</p>

              <p><span className="badge badge-secondary">AWS</span> <span className="badge badge-secondary">Umbraco</span>  <span className="badge badge-secondary">VueJS</span> <span className="badge badge-secondary">ecommerce</span></p>

              <h4 className="mt-4">UI Engineer for Project MATT</h4>

              <p>After leaving the MAG-O Retail I was re-hired by their new “Agency Task Team”, a team put together to support Manchester Airport Group’s various internal departments across Manchester, East Midlands and Stansted.</p>

              <p>In my short time here I developed a piece of digital signage to display flight information in East Midlands airport (Vue and Vuex), calling upon my experience of designing and building for IPTV.</p>

              <p><span className="badge badge-secondary">VueJS</span> <span className="badge badge-secondary">WordPress</span></p>

            </section>

            <section className="mb-5">
              <header className="mb-2">
                <h3>magneticNorth</h3>
                <time>January 2008—May 2015</time>
              </header>

              <p>During my time at mN I was responsible for developing high-quality front-end code (HTML5, CSS3, JavaScript), working with back-end systems such as the Zend Framework, WordPress and Umbraco to deliver large-scale projects for clients such as Kellogg’s, Google and The BBC.</p>

              <p>In addition I worked closely with the design team to design the user experience on many of our projects.</p>
            </section>

          </section>

        </div>
      </div>

    </div>
  </Layout>
)

export default CV
